



import { Component, Mixins } from 'vue-property-decorator'
import Collect from '@/components/organisms/study/Collect.vue'
import Contents2 from '@/views/student/study/Contents2.vue'

@Component({
  components: {
    Collect,
  },
})
export default class Collect4 extends Mixins(Contents2) {}
