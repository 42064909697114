
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Contents2 extends Vue {
  private contents = [
    {
      question_image: ['/img/contents/ZT16t2rihy0220700m01.png'],
      answer_image: '/img/contents/ZT16t2rihy0220700t01.png',
      count: 4,
      results: [
        { collect: true, explanation: '/student/explanatory-video/homework-1/3' },
        { collect: false, explanation: '/student/explanatory-video/homework-1/3' },
        { collect: false, explanation: '/student/explanatory-video/homework-1/3' },
        { collect: true, explanation: '/student/explanatory-video/homework-1/3' },
      ],
    },
    {
      question_image: ['/img/contents/ZT16t2rihy0230100m01.png'],
      answer_image: '/img/contents/ZT16t2rihy0230100t01.png',
      count: 4,
      results: [
        { collect: true, explanation: '/student/explanatory-video/homework-1/4' },
        { collect: true, explanation: '/student/explanatory-video/homework-1/4' },
        { collect: true, explanation: '/student/explanatory-video/homework-1/4' },
        { collect: false, explanation: '/student/explanatory-video/homework-1/4' },
      ],
    },
  ]
}
